import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

const PPUs = () => {
  return (
    <div className="PPEuropeAndTheUK page-service">
      <div className="page">
        <div className="page-contents">
          <div className="page-content">
            <h2>United States of America (US)</h2>
          </div>
          <div className="page-content">
            <h2>Your US State Privacy Rights</h2>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.</p>
            </div>
            <div className="content-right">
              <p>
                This US State Privacy Rights Statement supplements our{' '}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
                ; however, where the{' '}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
                conflicts with this US State Privacy Rights Statement, the US State Privacy Rights Statement will
                prevail as to persons located in the US.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>2.</p>
            </div>
            <div className="content-right">
              <p>
                Depending on your US state of residence, you may have certain rights in relation to your personal
                information that we collect, use or disclose in accordance with this Statement, including:
              </p>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(a)</p>
                </div>
                <div className="content-right">
                  <p>
                    <u>Right to know</u>: You may have the right to request that we disclose to you the categories and
                    specific pieces of personal information that we have collected about you, the categories of sources
                    from which we collected your personal information, our business or commercial purpose for
                    collecting, selling, or sharing your personal information, the categories of personal information
                    that we have disclosed for a business purpose, any categories of personal information about you that
                    we have sold, and the categories of third parties with whom we have disclosed your personal
                    information, if applicable. You may have the right to request your personal information in a
                    portable format.
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(b)</p>
                </div>
                <div className="content-right">
                  <p>
                    <u>Deletion</u>: You may request that we delete your personal information that we maintain about
                    you, subject to certain exceptions. As described in more detail in our{' '}
                    <Link to="/privacy-policy" target="_blank">
                      Privacy Policy
                    </Link>
                    , there are some reasons for which we will not be able to fully address your deletion request, such
                    as if we need to complete a transaction for you, to detect and protect against fraudulent and
                    illegal activity, to exercise our rights, or to comply with a legal obligation. You can also access,
                    update, and remove your information by contacting us at{' '}
                    <a href="mailto:dpo@juicyparenting.com.com">dpo@juicyparenting.com.com</a>.
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(c)</p>
                </div>
                <div className="content-right">
                  <p>
                    <u>Correction</u>: You can request that we correct inaccurate personal information that we maintain
                    about you.
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(d)</p>
                </div>
                <div className="content-right">
                  <p>
                    <u>Opt out of Sharing and Targeted Advertising</u>: Depending on the local laws in the US state you
                    reside in, you may request to opt out of our sharing of your personal information to third parties,
                    or the use and disclosure of your personal information for targeted advertising. To effect the opt
                    out, please contact us at <a href="mailto:dpo@juicyparenting.com.com">dpo@juicyparenting.com.com</a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>3.</p>
            </div>
            <div className="content-right">
              <p>
                Please contact us at <a href="mailto:dpo@juicyparenting.com.com">dpo@juicyparenting.com.com</a> to take
                advantage of any of these rights or to seek clarification on your specific rights, depending on your US
                state of residence. You may designate an authorised agent to make a request on your behalf. When
                submitting the request, please ensure the authorised agent is identified as an authorised agent. We may
                need to verify your identity or elements of the request to enable us to process your request. We will
                take reasonable steps to verify your identity when you submit a right to know, delete, or correct
                request; these verification steps will vary depending on the sensitivity of the personal information and
                whether you have an Account with us. We may deny certain requests, or fulfil a request only in part,
                based on our legal rights and obligations. For example, we may retain personal information as permitted
                by law, such as for tax or other record keeping purposes, to maintain an active Account, and to process
                transactions and facilitate customer requests. We value your privacy and will not discriminate against
                you in response to your exercise of privacy rights.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>4.</p>
            </div>
            <div className="content-right">
              <p>
                Depending on your US state of residence, you may have the right to appeal a decision we have made in
                connection with your privacy rights request. To appeal a decision, please contact{' '}
                <a href="mailto:dpo@juicyparenting.com.com">dpo@juicyparenting.com.com</a>.
              </p>
            </div>
          </div>
          <div className="page-content">
            <i>Last Updated: {moment('2024-10-28', 'YYYY-MM-DD').format('DD MMM YYYY')}</i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PPUs;
