import * as React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import LayoutAuth from '../Layouts/Auth';
import AuthPage from '../Pages/Auth';
import CoachingInquiryPage from '../Pages/CoachingInquiry';
import { Private } from '../modules/auth/containers';
import CoachesPage from '../Pages/Coaches';
import FormConfiguration from '../Pages/FormConfiguration';
import LogoutPage from '../Pages/Logout';
import UsersPage from '../Pages/Users';
import UserPage from '../Pages/User';
import TermsOfService from '../Pages/TermsOfService';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import CookiePolicy from '../Pages/CookiePolicy';
import PPEuropeAndTheUK from '../Pages/PPEuropeAndTheUK';
import PPUs from '../Pages/PPUs';

const router = createBrowserRouter([
  {
    path: '/login',
    element: (
      <LayoutAuth>
        <AuthPage />
      </LayoutAuth>
    ),
  },
  {
    path: '/logout',
    element: <LogoutPage />,
  },
  {
    path: '/coaching-inquiry',
    element: (
      <Private allowedRoles={['user']}>
        <CoachingInquiryPage />
      </Private>
    ),
  },
  {
    path: '/admin/coaches',
    element: (
      <Private allowedRoles={['admin']}>
        <CoachesPage />
      </Private>
    ),
  },
  {
    path: '/admin/users',
    element: (
      <Private allowedRoles={['admin']}>
        <UsersPage />
      </Private>
    ),
  },
  {
    path: '/admin/users/:id',
    element: (
      <Private allowedRoles={['admin']}>
        <UserPage />
      </Private>
    ),
  },
  {
    path: '/admin/form-configuration',
    element: (
      <Private allowedRoles={['admin']}>
        <FormConfiguration />
      </Private>
    ),
  },
  {
    path: '/terms-of-service',
    element: <TermsOfService />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/cookie-policy',
    element: <CookiePolicy />,
  },
  {
    path: '/pp-europe-and-the-uk',
    element: <PPEuropeAndTheUK />,
  },
  {
    path: '/pp-us',
    element: <PPUs />,
  },
  {
    path: '/*',
    element: <Navigate to="/login" replace />,
  },
]);

export default router;
