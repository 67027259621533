import React from 'react';
import './Login.scss';
import SignInForm from '../components/Forms/SignIn';
import { Button } from 'antd';
import { AuthPages } from '../Pages/Auth';
import { useDispatch } from 'react-redux';
import { doSignIn } from '../modules/auth/stores/thunk';
import { Link } from 'react-router-dom';

const LoginPage = ({ setCurrentPage }) => {
  const dispatch = useDispatch();

  const onLogin = (params) => {
    dispatch(doSignIn(params));
  };

  return (
    <div className="LoginPage">
      <div className="wrapper">
        <div className="header">
          <img src={`/wand-logo.png`} alt="Wand" />
        </div>
        <div className="body">
          <SignInForm onLogin={onLogin} />
        </div>
        <div className="footer">
          <div className="Register">
            <p>Don’t have an account?</p>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            onClick={() => setCurrentPage && setCurrentPage(AuthPages.register)}
          >
            Join Wand Coach.
          </Button>
        </div>
      </div>
      <div className="copyright">
        <p>Copyright © 2024 Wand Inspiration</p>
        <Link to="/terms-of-service" target="_blank">
          Terms & Conditions
        </Link>
      </div>
    </div>
  );
};

export default LoginPage;
