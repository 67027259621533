import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

const PPEuropeAndTheUK = () => {
  return (
    <div className="PPEuropeAndTheUK page-service">
      <div className="page">
        <div className="page-contents">
          <div className="page-content">
            <h2>Europe and the UK</h2>
          </div>
          <div className="page-content">
            <h2>General Data Protection Regulation Statement (“GDPR Statement”)</h2>
          </div>
          <div className="page-content">
            <p>
              This GDPR Statement applies to persons in the European Economic Area (“<b>EEA</b>”), including those based
              in the United Kingdom. This GDPR Statement supplements our{' '}
              <Link to="/privacy-policy" target="_blank">
                Privacy Policy
              </Link>
              . However, where the{' '}
              <Link to="/privacy-policy" target="_blank">
                Privacy Policy
              </Link>
              conflicts with the GDPR Statement, the GDPR Statement will prevail as to persons located in the EEA.
            </p>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>1.</b>
            </div>
            <div className="content-right">
              <b>Controller of Personal Information and Local Representative</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.1</p>
            </div>
            <div className="content-right">
              <p>
                WIN is the controller of the personal information we collect. Please contact us at{' '}
                <a href="mailto:dpo@juicyparenting.com.com">dpo@juicyparenting.com.com</a> for information relating to
                your rights regarding your personal information.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>2.</b>
            </div>
            <div className="content-right">
              <b>Information Rights Under GDPR</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>2.1</p>
            </div>
            <div className="content-right">
              <p>You have certain rights regarding your personal information. These rights include the following:</p>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(a)</p>
                </div>
                <div className="content-right">
                  <p>
                    The right to request access to your personal information. This enables you to receive a copy of the
                    personal information we hold about you.
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(b)</p>
                </div>
                <div className="content-right">
                  <p>
                    The right to request correction of your personal information if it is inaccurate. You may also
                    supplement any incomplete personal information we have, taking into account the purposes of the
                    processing.
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(c)</p>
                </div>
                <div className="content-right">
                  <p>The right to request deletion of your personal information if:</p>
                  <ul>
                    <li>
                      your personal information is no longer necessary for the purposes for which we collected or
                      processed them; or
                    </li>
                    <li>
                      you withdraw your consent if the processing of your personal information is based on consent and
                      no other legal ground exists; or
                    </li>
                    <li>
                      you object to the processing of your personal information and we do not have an overriding
                      legitimate ground for processing; or
                    </li>
                    <li>your personal information is unlawfully processed; or</li>
                    <li>your personal information must be deleted for compliance with a legal obligation.</li>
                  </ul>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(d)</p>
                </div>
                <div className="content-right">
                  <p>
                    The right to object to the processing of your personal information. We will comply with your
                    request, unless we have a compelling overriding legitimate interest for processing or we need to
                    continue processing your personal information to establish, exercise, or defend a legal claim.
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(e)</p>
                </div>
                <div className="content-right">
                  <p>The right to restrict the processing of personal information, if:</p>
                  <ul>
                    <li>
                      the accuracy of your personal information is contested by you, for the period in which we have to
                      verify the accuracy of the personal information; or
                    </li>
                    <li>
                      the processing is unlawful, and you oppose the deletion of your personal information and request
                      restriction; or
                    </li>
                    <li>
                      we no longer need your personal information for the purposes of processing, but your personal
                      information is required by you for legal claims; or
                    </li>
                    <li>
                      you have objected to the processing for the period in which we have to verify overriding
                      legitimate grounds.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="page-content">
                <div className="content-left">
                  <p>(f)</p>
                </div>
                <div className="content-right">
                  <p>
                    The right to data portability. You may request that we send this personal information to a third
                    party, where feasible. You only have this right if it relates to personal information you have
                    provided to us where the processing is based on consent or necessity for the performance of a
                    contract between you and us, and the processing is conducted by automated means.
                  </p>
                </div>
              </div>
              <div className="page-content">
                <div className="content-left">
                  <p>(g)</p>
                </div>
                <div className="content-right">
                  <p>You also have the right to lodge a complaint before your national data protection authority.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>2.2</p>
            </div>
            <div className="content-right">
              <p>
                You will not usually have to pay a fee to access your personal information (or to exercise any of the
                other rights described in this Statement). However, we may charge a reasonable fee if your request is
                clearly unfounded, repetitive, or excessive.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>2.3</p>
            </div>
            <div className="content-right">
              <p>
                We may need to request specific information from you to help us confirm your identity and ensure your
                right to access your personal information (or to exercise any of your other rights). To the extent you
                use a third party to submit a data request on your behalf, we may need to take reasonable steps to
                verify the authenticity of the request. These are security measures to ensure that personal information
                is not disclosed to any person who has no right to receive it. In an effort to speed up our response, we
                may also contact you to ask you for further information in relation to your request. To exercise your
                rights, please contact us at <a href="mailto:dpo@juicyparenting.com.com">dpo@juicyparenting.com.com</a>.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>3.</b>
            </div>
            <div className="content-right">
              <b>Information Uses and Legal Basis</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>3.1</p>
            </div>
            <div className="content-right">
              <p>We will only use your personal information under the circumstances permitted by the law or you.</p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>3.2</p>
            </div>
            <div className="content-right">
              <p>
                Pursuant to GDPR, we will use your personal information in one or more of the following circumstances:
              </p>
            </div>
          </div>
          <div className="page-content">
            <table>
              <thead>
                <tr>
                  <th>
                    <b>Information Uses</b>
                  </th>
                  <th>
                    <b>Information Processing Legal Basis</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>Registration and other contracts</p>
                  </td>
                  <td>
                    <p>
                      Where we need to perform the contract we are about to enter into or have entered into with you
                      and/or your employer.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Improve our Services, personalisation and customisation</p>
                  </td>
                  <td>
                    <p>
                      Where it is necessary for our legitimate interests (or those of a third party) to provide or
                      improve Services, including our interest to provide you improved and personalised Services, and
                      where your interests and fundamental rights do not override those interests.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Communication</p>
                  </td>
                  <td>
                    <p>
                      Where you are a User with a registered Account, the information users might be required to perform
                      the contract with you.
                    </p>

                    <p>
                      Where it is necessary for our legitimate interests (or those of a third party) to communicate with
                      you and your interests and fundamental rights do not override those interests.
                    </p>

                    <p>Where we need your consent for the information use, we will seek your prior consent.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Legal Compliance</p>
                  </td>
                  <td>
                    <p>Where it is necessary to meet our legal obligations.</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>4.</b>
            </div>
            <div className="content-right">
              <b>International Transfers</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>4.1</p>
            </div>
            <div className="content-right">
              <p>
                Your personal information may be stored or transferred to countries outside the EEA and the UK for the
                purposes described in this Statement. When we store or transfer your personal information outside the
                EEA and the UK, we take the precautions detailed in our Privacy Policy to ensure that your personal
                information is properly protected
              </p>
            </div>
          </div>
          <div className="page-content">
            <i>Last Updated: {moment('2024-10-28', 'YYYY-MM-DD').format('DD MMM YYYY')}</i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PPEuropeAndTheUK;
