import React from 'react';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';

const CookiePolicy = () => {
  const location = useLocation();
  console.log(location.pathname);
  return (
    <div className="PrivacyPolicy page-service">
      <div className="page">
        <h1>PRIVACY POLICY</h1>
        <div className="page-contents">
          <div className="page-content">
            <div className="content-left">
              <b>1.</b>
            </div>
            <div className="content-right">
              <b>Introduction</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.1</p>
            </div>
            <div className="content-right">
              <p>
                This is the Cookie Policy of <b>Wand Inspiration Network</b> Pte Ltd (Company No: 201333308H) (“
                <b>WIN</b>”, “<b>we</b>”, “<b>us</b>” or “<b>our</b>”).{' '}
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.2</p>
            </div>
            <div className="content-right">
              <p>
                This Cookie Policy is to be read together with our{' '}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>{' '}
                and forms part of the terms of use of our Wand Coach Platform and Services.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.2</p>
            </div>
            <div className="content-right">
              <p>
                This Cookie Policy is to be read together with our{' '}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>{' '}
                and forms part of the terms of use of our Wand Coach Platform and Services.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.3</p>
            </div>
            <div className="content-right">
              <p>
                Capitalised terms used but not defined in this Cookie Policy shall have the meanings set forth in our{' '}
                <Link to="/terms-of-service" target="_blank">
                  Terms of Service
                </Link>
                " and/or{' '}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
                .
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.4</p>
            </div>
            <div className="content-right">
              <p>
                If you do not agree with this Cookie Policy, you must terminate your Account with us, cease use of our
                Services and leave the Platform immediately.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.5</p>
            </div>
            <div className="content-right">
              <p>
                The headings are for convenience only and shall not affect the interpretation of this Cookie Policy.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.6</p>
            </div>
            <div className="content-right">
              <p>
                Unless the context otherwise requires or permits, references to the singular number shall include
                references to the plural number and vice versa; references to natural persons shall include bodies
                corporate and vice versa; and words denoting any gender shall include all genders.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.7</p>
            </div>
            <div className="content-right">
              <p>
                Like most websites, the Wand Coach Platform use cookies to collect information. Cookies are small data
                files which are placed on your computer or other devices (such as smart phones or tablets) as you browse
                and use our Platform. They are used to “remember” when your computer or device accesses our Platform.
                Cookies are essential for the effective operation of our Platform and to help you access our Platform
                and Services and manage your Account online.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>2.</b>
            </div>
            <div className="content-right">
              <b>Information Collected</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>2.1</p>
            </div>
            <div className="content-right">
              <p>
                Like most websites, the Wand Coach Platform use cookies to collect information. Cookies are small data
                files which are placed on your computer or other devices (such as smart phones or tablets) as you browse
                and use our Platform. They are used to “remember” when your computer or device accesses our Platform.
                Cookies are essential for the effective operation of our Platform and to help you access our Platform
                and Services and manage your Account online.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>3.</b>
            </div>
            <div className="content-right">
              <b>How Are Cookies Managed</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>3.1</p>
            </div>
            <div className="content-right">
              <p>
                The cookies stored on your computer or other device when you access our Platform is designed and used
                by:
              </p>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(a)</p>
                </div>
                <div className="content-right">
                  <p>
                    WIN, or on behalf of WIN, and are necessary to enable you to browse our Platform and access our
                    Services; and
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(b)</p>
                </div>
                <div className="content-right">
                  <p>Third Party Resources who assist WIN in delivering our Services.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>4.</b>
            </div>
            <div className="content-right">
              <b>Purpose & Type of Cookies Used</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>4.1</p>
            </div>
            <div className="content-right">
              <p>The Platform may use the cookies that perform four functions, as classified below:</p>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(a)</p>
                </div>
                <div className="content-right">
                  <p>
                    <b>Essential/strictly necessary cookies</b>, which are essential to the functioning of our Platform,
                    particularly in relation to online Account management and site navigation;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(b)</p>
                </div>
                <div className="content-right">
                  <p>
                    <b>Performance cookies</b>, which help us measure the Platform’s performance and improve your
                    experience. In using performance cookies, we do not store any personal data, and only use the
                    information collected through these cookies in aggregated and anonymised form;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(c)</p>
                </div>
                <div className="content-right">
                  <p>
                    <b>Functionality cookies</b>, which allow us to enhance your experience (for example by remembering
                    any settings you may have selected); and
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(d)</p>
                </div>
                <div className="content-right">
                  <p>
                    <b>Advertising/targeting cookies</b>, which we use to track user activity and sessions so that we
                    can deliver a more personalized service, and (in the case of advertising cookies) which are set by
                    the third parties with whom we execute advertising campaigns and allow us to provide advertisements
                    that are targeted and relevant to you.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>4.2</p>
            </div>
            <div className="content-right">
              <p>
                You can change your cookie settings and disable some or all cookies for the Platform at any time. You
                can also change your browser settings so that cookies cannot be placed on your device.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>5.</b>
            </div>
            <div className="content-right">
              <b>Third party data sharing</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>5.1</p>
            </div>
            <div className="content-right">
              <p>
                To bring you a personalised experience on our Platform and to facilitate our Services, we may partner
                with trusted Third Party Resources, who collect your data on our behalf and whom we share data with.
                These Third Party Resources may use cookies and similar technologies in connection with our Services. We
                may also work with third parties to help us provide our Services and for our own marketing purposes.
                Please note that access to and use of our Platform and Services shall also be subject to your acceptance
                and compliance with our Third Party Resource’s terms of use and privacy policies, as may be applicable
                to you.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>6.</b>
            </div>
            <div className="content-right">
              <b>How Do I Disable Cookies</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>6.1</p>
            </div>
            <div className="content-right">
              <p>
                If you wish to disable cookies, you would need to change your website browser settings to reject
                cookies. How you can do this will depend on the browser you use. Further details on how to disable
                cookies for the most popular browsers are set out below:
              </p>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(a)</p>
                </div>
                <div className="content-right">
                  <p>
                    <a
                      href="https://support.apple.com/en-gb/guide/safari/sfri11471/17.0/mac/14.0"
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      Apple Safari
                    </a>
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(b)</p>
                </div>
                <div className="content-right">
                  <p>
                    <a
                      href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en"
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      Google Chrome
                    </a>
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(c)</p>
                </div>
                <div className="content-right">
                  <p>
                    <a
                      href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      Mozilla Firefox
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>7.</b>
            </div>
            <div className="content-right">
              <b>What Happens If You Disable Cookies</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>7.1</p>
            </div>
            <div className="content-right">
              <p>
                This depends on which cookies you disable, but in general the Platform may not operate properly if
                cookies are switched off. If you disable all cookies, it may affect the functionality of our Platform
                and you may be unable to access our Platform and/or Services.
              </p>
            </div>
          </div>
        </div>

        <div className="page-content">
          <i>Last Updated: {moment('2024-10-28', 'YYYY-MM-DD').format('DD MMM YYYY')}</i>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
