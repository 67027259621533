import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="PrivacyPolicy page-service">
      <div className="page">
        <h1>PRIVACY POLICY</h1>
        <div className="page-contents">
          <div className="page-content">
            <div className="content-left">
              <b>1.</b>
            </div>
            <div className="content-right">
              <b>Introduction</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.1</p>
            </div>
            <div className="content-right">
              <p>
                At Wand Coach, we take your privacy seriously and have in place policies and practices dealing with data
                protection (“<b>Privacy Policy</b>” or “<b>Policy</b>”). We ask that you read this Privacy Policy
                carefully as it contains important information about our collection, use, processing and disclosure of
                information in our possession or under our control, including information on:
              </p>
              <div className="page-content">
                <div className="content-left">
                  <p>(a)</p>
                </div>
                <div className="content-right">
                  <p>our policies and practices for meeting our obligations under applicable laws;</p>
                </div>
              </div>
              <div className="page-content">
                <div className="content-left">
                  <p>(b)</p>
                </div>
                <div className="content-right">
                  <p>
                    how you may access, correct or make other requests in relation to your personal information in our
                    possession or control as permitted by applicable laws; and
                  </p>
                </div>
              </div>
              <div className="page-content">
                <div className="content-left">
                  <p>(c)</p>
                </div>
                <div className="content-right">
                  <p>details of our data protection complaints procedure.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.2</p>
            </div>
            <div className="content-right">
              <p>
                This Policy is owned by <b>Wand Inspiration Network</b> Pte Ltd (Company No: 201333308H) (“<b>WIN</b>”,
                “<b>we</b>”, “<b>us</b>”, and “<b>our</b>”).
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.3</p>
            </div>
            <div className="content-right">
              <p>
                Capitalised terms used but not defined in this Policy shall have the meanings set forth in our{' '}
                <Link to="/terms-of-service" target="_blank">
                  {' '}
                  Terms of Service
                </Link>
                .
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.4</p>
            </div>
            <div className="content-right">
              <p>
                Your access to the Wand Coach Platform and use of our Services is strictly subject to your acceptance of
                this Policy as well as all the terms referred to and set out in our{' '}
                <Link to="/terms-of-service" target="_blank">
                  {' '}
                  Terms of Service
                </Link>
                . If you do not agree with this Policy, you must terminate your Account with us, cease use of our
                Services and leave the Platform immediately.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.5</p>
            </div>
            <div className="content-right">
              <p>References to Clauses are to the clauses of this Policy.</p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.6</p>
            </div>
            <div className="content-right">
              <p>The headings are for convenience only and shall not affect the interpretation of this Policy.</p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.7</p>
            </div>
            <div className="content-right">
              <p>
                Unless the context otherwise requires or permits, references to the singular number shall include
                references to the plural number and vice versa; references to natural persons shall include bodies
                corporate and vice versa; and words denoting any gender shall include all genders.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>2.</b>
            </div>
            <div className="content-right">
              <b>Who Do We Collect information from</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>2.1</p>
            </div>
            <div className="content-right">
              <p>We collect and use information about the following persons:</p>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(a)</p>
                </div>
                <div className="content-right">
                  <p>Visitors to our Platform;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(b)</p>
                </div>
                <div className="content-right">
                  <p>Users of our Platform; and</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(c)</p>
                </div>
                <div className="content-right">
                  <p>anyone contacting Wand Coach through our Platform.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>2.2</p>
            </div>
            <div className="content-right">
              <p>
                WIN reserves the right, in its sole discretion, to vary any part of this Policy any time. In the event
                of any changes to this Policy, WIN will update the “Last Updated” date at the bottom of this page and/or
                post a notification on the Wand Coach Platform landing page. A User’s continued use of the Platform
                and/or Services after any such changes to this Policy constitutes acceptance of the latest Policy.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>3.</b>
            </div>
            <div className="content-right">
              <b>What Personal Data Do We Collect</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>3.1</p>
            </div>
            <div className="content-right">
              <p>
                Depending on your use of our Platform and Services, you may be asked to provide us with certain
                information in order for us to provide our Services and to share information that may be of interest.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>3.2</p>
            </div>
            <div className="content-right">
              <p>The information that we collect about you may include the following:</p>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(a)</p>
                </div>
                <div className="content-right">
                  <p>
                    <b>Identity and profile data</b>, which may include – names, e-mail address, passwords, telephone
                    numbers, country of residence, age range, career stage, preferred coach characteristics, top three
                    (3) coaching goals (out of a list of pre-determined coaching goals) and any other relevant
                    information that you may voluntarily share on the Platform and/or with your WIN coach. If you attend
                    any of our events, we may also collect images or motions captured and recorded by cameras;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(b)</p>
                </div>
                <div className="content-right">
                  <p>
                    <b>Account data</b>, which may include – details relating to the WIN coaches that have been matched
                    with you and the number of times you have used our Platform and/or Services;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(c)</p>
                </div>
                <div className="content-right">
                  <p>
                    <b>Online activity data</b>, which may include - details of when you use the Platform, such as pages
                    you viewed, how long you spent on a page, navigation paths between pages, information about your
                    activity on a page, access times and duration of access;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(d)</p>
                </div>
                <div className="content-right">
                  <p>
                    <b>Marketing and communications data</b>, which may include – details of your interests, survey
                    responses, marketing material and promotional interests, communication preferences as well as your
                    messages and e-mail history with the Platform, your WIN coaches, or any Third Party Resources; and
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(e)</p>
                </div>
                <div className="content-right">
                  <p>
                    <b>Location data</b>, which may include – the IP address you use.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>3.3</p>
            </div>
            <div className="content-right">
              <p>Information may be collected directly or indirectly, for instance, when you:</p>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(a)</p>
                </div>
                <div className="content-right">
                  <p>visit/browse our Platform;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(b)</p>
                </div>
                <div className="content-right">
                  <p>create an Account on our Platform;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(c)</p>
                </div>
                <div className="content-right">
                  <p>use our Services via our Platform;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(d)</p>
                </div>
                <div className="content-right">
                  <p>subscribe to our mailing lists and newsletter;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(e)</p>
                </div>
                <div className="content-right">
                  <p>
                    communicate with us via telephone, chat, e-mail or any video conferencing services provide by Third
                    Party Resources;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(f)</p>
                </div>
                <div className="content-right">
                  <p>submit any forms, applications or registrations to us when using any of our Services;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(g)</p>
                </div>
                <div className="content-right">
                  <p>sign up and/or register for any of our events;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(h)</p>
                </div>
                <div className="content-right">
                  <p>attend any of our events; and</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(i)</p>
                </div>
                <div className="content-right">
                  <p>submit your information to us for any reason.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>3.4</p>
            </div>
            <div className="content-right">
              <p>
                We may also collect information from Third Party Resources, for example from our business partners and
                service providers who provide communication, advertising, marketing or promotional services to us as
                well as from public sources.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>3.5</p>
            </div>
            <div className="content-right">
              <p>
                In certain circumstances, you may provide us with information of persons other than yourself. If you do
                so, you warrant that you have informed the said person of the purposes for which we are collecting his
                information and that he has consented to your disclosure of his information to us for those purposes,
                including all purposes as set out in this Policy.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>4.</b>
            </div>
            <div className="content-right">
              <b>Purposes Of Collection, Use and/or Disclosure</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>4.1</p>
            </div>
            <div className="content-right">
              <p>
                We generally process your information when we need to fulfil a contractual obligation (for example, to
                complete your Account registration, or authorise you to use our Platform and Services), or where we or
                someone we work with needs to use your personal information for a reason related to their business (for
                example, to provide you with a service).
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>4.2</p>
            </div>
            <div className="content-right">
              <p>
                In this regard, we may collect, use, process, store and/or disclose your information for one or more of
                the following purposes, to:
              </p>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(a)</p>
                </div>
                <div className="content-right">
                  <p>verify your identity;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(b)</p>
                </div>
                <div className="content-right">
                  <p>create your Account;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(c)</p>
                </div>
                <div className="content-right">
                  <p>investigate and rectify technical issues;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(d)</p>
                </div>
                <div className="content-right">
                  <p>
                    conduct feedback, analysis and development activities to improve your experience, our Platform and
                    Services;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(e)</p>
                </div>
                <div className="content-right">
                  <p>
                    conduct and complete transactions (e.g. providing Services that have been requested), performing our
                    obligations or exercising our contractual rights, and otherwise to manage your relationship with us;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(f)</p>
                </div>
                <div className="content-right">
                  <p>
                    provide customer service (e.g. responding to your queries, providing information on our Services,
                    status and updates);
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(g)</p>
                </div>
                <div className="content-right">
                  <p>for crime and fraud prevention, detection and related purposes;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(h)</p>
                </div>
                <div className="content-right">
                  <p>for medical and safety purposes;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(i)</p>
                </div>
                <div className="content-right">
                  <p>
                    for compliance with our{' '}
                    <Link to="/terms-of-service" target="_blank">
                      Terms of Service
                    </Link>
                    ;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(j)</p>
                </div>
                <div className="content-right">
                  <p>
                    with your consent, to contact you electronically about promotional offers, events and Services which
                    we think may interest you;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(k)</p>
                </div>
                <div className="content-right">
                  <p>
                    respond to legal processes or to comply generally with any applicable laws, governmental or
                    regulatory requirements of any relevant jurisdiction (and any applicable rules, codes of practice or
                    guidelines), and requests of any governmental or other regulatory authority with jurisdiction over
                    us, including, without limitation, meeting requirements to disclose under the requirements of any
                    applicable law, assisting in law enforcement and investigations by relevant authorities, whether in
                    Singapore or elsewhere;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(l)</p>
                </div>
                <div className="content-right">
                  <p>such purposes that may be informed to you when your personal information is collected;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(m)</p>
                </div>
                <div className="content-right">
                  <p>
                    any other reasonable purposes related to the aforesaid or for which you have provided any
                    information to us; and/or
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(n)</p>
                </div>
                <div className="content-right">
                  <p>any other incidental business purposes related to or in connection with the above.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>4.3</p>
            </div>
            <div className="content-right">
              <p>
                The purposes listed in the above Clause may continue to apply even in situations where your relationship
                with us has been terminated or altered in any way (for example, where we use personal information to
                enforce our legal rights against you).
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>5.</b>
            </div>
            <div className="content-right">
              <b>Disclosure Of Your Personal Data</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>5.1</p>
            </div>
            <div className="content-right">
              <p>
                Depending on the Service we are providing to you, we may disclose your personal data to the following
                parties (whether located within or outside Singapore):
              </p>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(a)</p>
                </div>
                <div className="content-right">
                  <p>Your matched WIN coach(es);</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(b)</p>
                </div>
                <div className="content-right">
                  <p>
                    Third parties to whom you authorise our disclosure of your personal information (through this Policy
                    or otherwise);
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(c)</p>
                </div>
                <div className="content-right">
                  <p>Our auditors, accountants, professional consultants, lawyers and other advisors;</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(d)</p>
                </div>
                <div className="content-right">
                  <p>
                    Our authorised business partners and Third Party Resources who support the Services we provide to
                    you; and
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(e)</p>
                </div>
                <div className="content-right">
                  <p>
                    Any governmental agencies, courts, tribunals, law enforcement agencies, or regulatory authorities as
                    agreed or authorised by law.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>5.2</p>
            </div>
            <div className="content-right">
              <p>
                We may also disclose your information to a third-party in connection with a proposed or actual business
                transaction, such as a corporate reorganisation, merger or acquisition, or the sale of some of all of
                our business or assets (including in connection with bankruptcy proceedings). We will require that the
                information recipient agrees to protect the privacy of your information in a manner that is consistent
                with this Policy. This could include all of your information relevant to the business being acquired.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>6.</b>
            </div>
            <div className="content-right">
              <b>Accuracy</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>6.1</p>
            </div>
            <div className="content-right">
              <p>
                We generally rely on the accuracy of the information provided by you or your authorised representatives.
                In order to ensure that our records of your information are current, complete and accurate, please
                update us if there are changes to your information by writing to our Data Protection Officer at{' '}
                <a href="mailto:dpo@juicyparenting.com.com">dpo@juicyparenting.com.com</a>.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>7.</b>
            </div>
            <div className="content-right">
              <b>Other Third Party Resources</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>7.1</p>
            </div>
            <div className="content-right">
              <p>
                Please note that access to and use of our Services shall also be subject to your acceptance and
                compliance with any applicable Third Party Resource’s terms of use and privacy policies as further
                detailed in our{' '}
                <Link to="/terms-of-service" target="_blank">
                  Terms of Service
                </Link>
                .
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>7.2</p>
            </div>
            <div className="content-right">
              <p>
                Our Platform and/or Services may contain links to other websites. However, this Policy only applies to
                the Wand Coach Platform and Services, and if you visit other websites, including through such links,
                different privacy policies will apply.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>8.</b>
            </div>
            <div className="content-right">
              <b>Your Rights Over Your Information</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>8.1</p>
            </div>
            <div className="content-right">
              <p>
                We believe you should be able to access and control your personal information no matter where you live.
                Depending on how you use our Platform and Services, you may have the right to request access to,
                correct, amend, delete, export to another service provider, restrict, or object to certain uses of your
                personal information (for example, direct marketing) as permitted by applicable data protection laws.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>8.2</p>
            </div>
            <div className="content-right">
              <p>
                In this regard, if you wish to exercise your rights, you may submit your request by contacting our Data
                Protection Officer at <a href="mailto:dpo@juicyparenting.com.com">dpo@juicyparenting.com.com</a>. You
                may be required to submit certain forms or provide certain information in order for your request to be
                processed. Where permitted by law, we may charge you a reasonable fee to process your request, and will
                inform you of the fee before processing your request.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>8.3</p>
            </div>
            <div className="content-right">
              <p>
                We will respond to your request as soon as reasonably possible, and will strive to do so within{' '}
                <b>thirty (30)</b> days of receiving your request.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>9.</b>
            </div>
            <div className="content-right">
              <b>Where We Send Your Information</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>9.1</p>
            </div>
            <div className="content-right">
              <p>
                We are a proudly Singaporean company, but we work with individuals across the world to operate our
                business and provide our Services, for which we may send your personal information outside of Singapore.
                This data may be subject to the laws of the countries where we send it. When we send your information
                across borders, we take reasonable steps to protect your information, and we try to only send your
                information to countries that have data protection laws similar to Singapore.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>10.</b>
            </div>
            <div className="content-right">
              <b>Protection Of Your Personal Data</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>10.1</p>
            </div>
            <div className="content-right">
              <p>
                Your data will be stored in a secure cloud, with reliable privacy and security standards. To safeguard
                your personal data from unauthorised access, collection, use, disclosure, copying, modification,
                disposal or similar risks, we have also introduced appropriate administrative, physical and technical
                measures such as minimised collection of personal data, authentication and access controls (such as good
                password practices), need-to-basis for data disclosure, encryption of data and data anonymisation (where
                applicable).
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>10.2</p>
            </div>
            <div className="content-right">
              <p>
                However, you should be aware that no method of transmission over the Internet, and method of electronic
                storage, is completely secure. This means we cannot and do not guarantee the absolute security of your
                personal information.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>10.3</p>
            </div>
            <div className="content-right">
              <p>
                We keep your information only for so long as we need the information to fulfil the purpose we collected
                it for, and to satisfy our business and/or legal purposes, including audit, accounting or reporting
                requirements. In some circumstances, we may anonymise your information so that it can no longer be
                associated with you, in which case we are entitled to retain and use such data without restriction.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>11.</b>
            </div>
            <div className="content-right">
              <b>Europe, United Kingdom & United States</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>11.1</p>
            </div>
            <div className="content-right">
              <p>
                If you are in the European Economic Area (“EEA”), including those based in the United Kingdom,
                additional rights may apply to you. Please{' '}
                <Link to="/pp-europe-and-the-uk" target="_blank">
                  click here
                </Link>{' '}
                for our GDPR Statement.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>11.2</p>
            </div>
            <div className="content-right">
              <p>
                Depending on your United States state of residence, you may have certain additional rights in relation
                to your personal information. Please{' '}
                <Link to="/pp-us" target="_blank">
                  click here
                </Link>{' '}
                for our US State Privacy Statement.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>12.</b>
            </div>
            <div className="content-right">
              <b>Cookies and Website Tracking</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>12.1</p>
            </div>
            <div className="content-right">
              <p>
                We use cookies and similar tracking technologies on our Platform and when providing our Services, for
                purposes such as monitoring the number of times you visit our Platform and use our Services, which pages
                you go to, your activities on our Platform and how long you spend browsing each page.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>12.2</p>
            </div>
            <div className="content-right">
              <p>
                Information we may collect include device information, identifier address, IP address and operating
                system used. This information helps us build a profile of users and improve our Platform and Services,
                and track any potential use of bots or automated programming on our Platform. When data is only used on
                an aggregated basis, we will be unable to identify any particular individual, and we generally track on
                per-device basis.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>12.2</p>
            </div>
            <div className="content-right">
              <p>
                Information we may collect include device information, identifier address, IP address and operating
                system used. This information helps us build a profile of users and improve our Platform and Services,
                and track any potential use of bots or automated programming on our Platform. When data is only used on
                an aggregated basis, we will be unable to identify any particular individual, and we generally track on
                per-device basis.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>12.3</p>
            </div>
            <div className="content-right">
              <p>
                For more information about how we use cookies on our Platform, including the types of cookies used, and
                an explanation of how you can opt out of certain types of cookies, please see our{' '}
                <Link to="/cookie-policy" target="_blank">
                  Cookie Policy
                </Link>
                .
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>13.</b>
            </div>
            <div className="content-right">
              <b>Consent Withdrawals</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>13.1</p>
            </div>
            <div className="content-right">
              <p>
                The consent that you provide for the collection, use and disclosure of your personal information will
                remain valid until such time it is being withdrawn by you. You may withdraw consent and request us to
                stop using and/or disclosing your information by submitting a request in writing to our Data Protection
                Officer at <a href="mailto:dpo@juicyparenting.com.com">dpo@juicyparenting.com.com</a>.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>13.2</p>
            </div>
            <div className="content-right">
              <p>
                Upon receipt of your written request to withdraw your consent, we may require reasonable time (depending
                on the complexity of the request and its impact on our relationship with you) for your request to be
                processed and for us to notify you of the consequences of us acceding to the same, including any legal
                consequences which may affect your rights and liabilities to us. In general, we shall seek to process
                your request within <b>thirty (30)</b> days of receiving it.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>13.3</p>
            </div>
            <div className="content-right">
              <p>
                We will investigate your queries, comments or complaints relating to your information and this Policy,
                and we will use reasonable endeavours to respond, in accordance with applicable laws.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>13.4</p>
            </div>
            <div className="content-right">
              <p>
                If you withdraw your consent to any or all use of your personal information or do not consent to the
                collection of your information by us, we may not be able to provide or continue providing you with
                access to our Platform and Services which you have requested and such withdrawal may also constitute a
                termination event.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>13.5</p>
            </div>
            <div className="content-right">
              <p>
                You understand and agree that in such instances where we require your personal information to fulfil a
                contractual obligation to you and you withdraw your consent to collect, use or disclose the relevant
                information for those purposes, we cannot be held liable for breach of that agreement. WIN’s legal
                rights and remedies in such event are expressly reserved.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>13.6</p>
            </div>
            <div className="content-right">
              <p>
                Please note that withdrawing consent does not affect our right to continue to collect, use and disclose
                personal information where such collection, use and disclosure without consent is permitted or required
                under applicable laws in any jurisdiction.
              </p>
            </div>
          </div>
          <div className="page-content">
            <i>Last Updated: {moment('2024-10-28', 'YYYY-MM-DD').format('DD MMM YYYY')}</i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
