import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="TermsOfServicePage page-service">
      <div className="page">
        <h1>Terms of Service</h1>
        <div className="page-contents">
          <div className="page-content">
            <div className="content-left">
              <b>1.</b>
            </div>
            <div className="content-right">
              <b>Introduction</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.1</p>
            </div>
            <div className="content-right">
              <p>
                <b>Wand Inspiration Network Pte Ltd</b> (Company No: 201333308H) is a company incorporated in Singapore
                with its principal address at 19 Burnfoot Terrace, Singapore 459811(“<b>WIN</b>”).
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.2</p>
            </div>
            <div className="content-right">
              <p>WIN provides skills training and coaching programmes to improve personal and business results.</p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.3</p>
            </div>
            <div className="content-right">
              <p>
                WIN is the owner and operator of Wand Coach (
                <a href={process.env.REACT_APP_PUBLIC_URL}>{process.env.REACT_APP_PUBLIC_URL}</a>)(“
                <b>Platform”</b>), an online platform designed to make executive, career and life coaching ("
                <b>Services</b>) accessible and efficient for professionals at all career stages and ages (“<b>Users</b>
                ”).
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>1.4</p>
            </div>
            <div className="content-right">
              <p>
                Wand Coach operates as a coach-on-demand model, where participants are matched to a WIN coach through a
                series of questions and answers carefully selected for the participant and using WIN’s proprietary
                algorithm.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>2.</b>
            </div>
            <div className="content-right">
              <b>This Terms</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>2.1</p>
            </div>
            <div className="content-right">
              <p>
                This Terms of Service (“Terms”) govern all users of the Platform and/or Services and constitute a legal
                and binding agreement between Users and WIN. By using or otherwise accessing the Platform and/or
                Services, Users are confirming that they have read, understood and agreed to this Terms as well as to
                WIN’s{' '}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link to="/cookie-policy" target="_blank">
                  Cookie Policy
                </Link>
                . If you do not agree to this Terms as well as to WIN’s{' '}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link to="/cookie-policy" target="_blank">
                  Cookie Policy
                </Link>
                , you must terminate your Account (as defined below) with us, cease use of our Services and leave the
                Platform immediately.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>2.2</p>
            </div>
            <div className="content-right">
              <p>
                The access to or use of certain areas or features of the Platform and/or Services may be subject to
                separate policies, standards or guidelines, or may require Users to accept additional terms and
                conditions as published on the Platform.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>2.3</p>
            </div>
            <div className="content-right">
              <p>
                WIN reserves the right, in its sole discretion, to vary any part of this Terms any time. Users should
                periodically visit this page to review the current Terms so that they are aware of any revision by which
                they are bound. In the event of any changes to this Terms, WIN will update the “Last Updated” date at
                the bottom of this page and/or post a notification on the Wand Coach Platform landing page. A User’s
                continued use of the Platform and/or Services after any such changes to this Terms constitutes
                acceptance of the latest Terms.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>2.4</p>
            </div>
            <div className="content-right">
              <p>
                Any additional and other business terms (e.g. terms and conditions relating to our engagement by our
                clients, our terms of payment, delivery of Services, etc) shall be read in conjunction with and shall,
                where applicable, incorporate this Terms.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>3.</b>
            </div>
            <div className="content-right">
              <b>Interpretation</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>3.1</p>
            </div>
            <div className="content-right">
              <p>
                In this Terms, the following words and expressions have the following meanings unless inconsistent with
                the context or stated otherwise:
              </p>
              <p>
                “<b>Content”</b> means any data, text, software, music, sound, photographs, graphics, video, messages or
                other materials, including but without limitation to, company logos, trademarks, copyrighted materials
                and Third Party Resources, displayed, published or made available through the Platform and/or our
                Services;
              </p>
              <p>
                “<b>Event of Force Majeure</b>” means any cause beyond the reasonable control of a Party to this Terms,
                including any act of God, outbreak, or epidemic of any kind, communicable and virulent disease, strike,
                flood, fire, embargo, boycott, act of terrorism, insurrection, war, explosion, civil disturbance,
                shortage of gas, fuel or electricity, interruption of transportation, governmental order, unavoidable
                accident, or shortage of labour or raw materials;
              </p>
              <p>
                “<b>Intellectual Property Rights</b>” means all intellectual property rights (whether registered or not)
                including, but not limited to, copyright (including copyright in computer software), patents, logos,
                trademarks or business names, design rights, database rights, know-how, trade secrets and rights of
                confidence in connection with the Platform, Services and/or Content;
              </p>
              <p>
                “<b>WIN”</b>, “<b>we”</b>, “<b>us</b>”, “<b>our</b>” means, for the purposes of this Terms, the{' '}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
                and the{' '}
                <Link to="/cookie-policy" target="_blank">
                  Cookie Policy
                </Link>
                , Wand Inspiration Network Pte Ltd;
              </p>
              <p>
                “<b>Wand Coach technology</b>” means WIN’s proprietary technology underlying the Platform which uses a
                complex matching system based on multiple factors to find the most suitable coach for a User by
                considering, among others, the User’s career stage, age range, language preference, preferred coach
                gender, preference for in-person or virtual coaching, the User’s top coaching goals selected from a list
                of pre-determined options and any keywords used by a User in the free text. This includes internet
                operations design, content, software tools, hardware designs, algorithms, software (in source and object
                forms), source codes, user interface designs, architecture, class libraries, objects and documentation
                (both printed and electronic), know-how, trade secrets and any related intellectual property rights
                throughout the world (whether owned by WIN or its licensors) in connection with the Platform and/or
                Services, and also including any derivatives, improvements, enhancements or extensions of the technology
                conceived, reduced to practice, or developed by WIN from time to time that are not uniquely applicable
                to Users or that have general applicability in the art;
              </p>
              <p>
                “<b>Parties”</b> means collectively WIN and a relevant User, and a “<b>Party</b>” means any of them;
              </p>
              <p>
                “<b>Personal Data</b>” means data that may enable WIN to identify Users personally, including data about
                a person who can be identified (i) from that data, or (ii) from that data and other information to which
                WIN has or are likely to have access;
              </p>
              <p>
                “<b>User(s)</b>”, “<b>you</b>”, “<b>your</b>” means, for the purposes of this Terms, the{' '}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>{' '}
                and the{' '}
                <Link to="/cookie-policy" target="_blank">
                  Cookie Policy
                </Link>
                , any user of the Platform and/or Services, including our clients.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>3.2</p>
            </div>
            <div className="content-right">
              <p>References to Clauses are to the clauses of this Terms.</p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>3.3</p>
            </div>
            <div className="content-right">
              <p>The headings are for convenience only and shall not affect the interpretation of this Terms.</p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>3.4</p>
            </div>
            <div className="content-right">
              <p>
                Unless the context otherwise requires or permits, references to the singular number shall include
                references to the plural number and vice versa; references to natural persons shall include bodies
                corporate and vice versa; and words denoting any gender shall include all genders.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>4.</b>
            </div>
            <div className="content-right">
              <b>Eligibility To Use</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>4.1</p>
            </div>
            <div className="content-right">
              <p>
                Users must be at least eighteen (18) years old and able to enter into legally binding contracts to
                access and use the Platform, Services and/or register an Account (defined below) as a User. If a
                corporate entity is accessing or using the Platform and/or Services, the person registering for an
                Account for and on behalf of a corporate entity represents and warrants that he has the requisite power
                and authority to bind such corporate entity to this Terms.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>4.2</p>
            </div>
            <div className="content-right">
              <p>
                For transparency and fraud prevention purposes, and to the extent permitted by law, WIN reserves the
                right, but have no obligation, to (i) ask Users to provide a form of government identification or other
                information or undertake additional checks designed to help verify the identity or background of Users,
                and (ii) screen Users against third party databases or other sources.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>5.</b>
            </div>
            <div className="content-right">
              <b>Account Registration</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>5.1</p>
            </div>
            <div className="content-right">
              <p>
                Users are required to register through the Platform and maintain an account with WIN (“<b>Account</b>”)
                in order to use the Services. By registering an Account, Users agree to provide and maintain true,
                accurate, current and complete information about themselves as prompted by the relevant registration
                form or when required to verify the same before Account activation.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>5.2</p>
            </div>
            <div className="content-right">
              <p>
                By registering an Account, Users authorise WIN to collect, store, retain, process use and disclose any
                Personal Data provided to WIN in accordance with WIN’s{' '}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
                . WIN reserves the right to amend this Privacy Policy. Users’ failure to provide, maintain accurate,
                complete and up-to-date personal data (including having an invalid or expired professional e-mail
                address) to enable WIN to properly perform the Services may result in Users being unable to access and
                use the Platform and/or Services. WIN reserves the right, in its sole discretion, to approve, refuse,
                suspend and/or terminate the registration of an Account.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>5.3</p>
            </div>
            <div className="content-right">
              <p>
                Users may not assign or otherwise transfer their Accounts to any party without the prior written
                authorisation by WIN. Users are solely responsible for maintaining the confidentiality of their password
                and other information relating to their Accounts, and any and all activities that occur under their
                Accounts. Users agree to (i) immediately notify WIN of any unauthorised use of their Accounts, password,
                or any other breach of security, and (ii) ensure that Users exit from their Accounts at the end of each
                session. WIN shall not be liable for any loss or damage arising from Users’ failure to comply with this
                provision.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>5.4</p>
            </div>
            <div className="content-right">
              <p>
                Users acknowledge that WIN may establish general practices and limits concerning use of the Platform
                and/or Services and agree that WIN has no responsibility or liability for the deletion or failure to
                store any data or other content maintained or transmitted by the Platform and/or Services. Users
                acknowledge that WIN reserves the right to terminate any Account that is inactive for an extended period
                of time. Users further acknowledge that WIN reserves the right to change these general practices and
                limits at any time, in its sole discretion, with or without notice.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>5.5</p>
            </div>
            <div className="content-right">
              <p>
                By registering an Account, Users agree to receive informative, promotional or marketing e-mails or
                communications in connection with the Platform and/or Services but they may opt-out from receiving such
                communications by clicking the “Unsubscribe” link at the bottom of the e-mail or exercise their rights
                as owner of their Personal Data under the{' '}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
                .
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>6.</b>
            </div>
            <div className="content-right">
              <b>Our Services at Wand Coach</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>6.1</p>
            </div>
            <div className="content-right">
              <p>
                Once a User has registered an Account and selects their career stage, age range, preferred coach
                characteristics, language preference, preferred mode of coaching and communication, country of
                residence, and their top three (3) coaching goals (out of a list of pre-determined coaching goals) on
                the Platform, the User will be immediately matched with a suitably experienced WIN coach. Both the User
                and the WIN coach will receive an e-mail informing them of the match and the WIN coach will then proceed
                to contact the User via his/her chosen mode of communication as indicated during his/her registration
                process to schedule the coaching session (“<b>Coaching session</b>”).
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>6.2</p>
            </div>
            <div className="content-right">
              <p>
                Each Coaching session is for a duration of sixty (60) minutes. It is a User’s responsibility to notify
                his Coach fourty-eight (48) hours in advance of a scheduled Coaching session in the event of any
                cancellation. WIN reserves the right to charge a cancellation fee where applicable. Your WIN coach will
                in good faith attempt to reschedule a Coaching session that was cancelled.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>6.3</p>
            </div>
            <div className="content-right">
              <p>
                Wand Coach is designed for a broad spectrum of professionals at various stages of their careers and
                across all age groups. The Platform and our Services accommodates Users from age twenty-one (21) to
                sixty (60) and above, recognising that professional development is a lifelong journey. Wand Coach is
                suitable for anyone seeking to improve in areas such as achieving results, building resilience,
                enhancing communication, managing stakeholders or addressing work-life satisfaction.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>6.4</p>
            </div>
            <div className="content-right">
              <p>
                Given the broad scope of Wand Coach, there may be some professionals who might find our Services less
                suitable. This may include (but is not limited to) those who seek highly specialised and/or technical
                coaching outside the Platform’s pre-determined coaching goals, individuals requiring long-term,
                intensive coaching relationships, those uncomfortable with digital platforms or algorithm-based
                matching, and individuals who require medical treatment for mental health issues. We encourage
                professionals who might find our Services less suitable to seek appropriate help.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>6.5</p>
            </div>
            <div className="content-right">
              <p>
                At Wand Coach, WIN does not track the progress of each User’s Coaching session. Wand Coach is designed
                to work such that Users may only need one Coaching session to discuss a challenge/goal with their coach,
                co-create solutions and action plans. Users may choose different goal(s) and/or different coach(es) for
                their subsequent sessions or choose the same coach to work on same or different goals. The unique
                perspective of Wand Coach is that Users have autonomy on what they wish to be coached on.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>6.6</p>
            </div>
            <div className="content-right">
              <p>
                For privacy reasons, WIN and/or our coaches do not record any of the Coaching sessions. The Coaching
                sessions are private and confidential as between the User and his/her coach (unless in instances where
                harm is indicated, as detailed below). WIN will only have anonymous data collected on the frequency of
                goals chosen by the Users for reporting back to the Users’ employer/WIN’s client for research and/or for
                WIN’s quality improvement purposes.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>7.</b>
            </div>
            <div className="content-right">
              <b>WIN coaches</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>7.1</p>
            </div>
            <div className="content-right">
              <p>
                At WIN, our team of coaches are professionally certified and undergo regular training to stay updated on
                the latest coaching techniques and maintain consistency across the Platform.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>7.2</p>
            </div>
            <div className="content-right">
              <p>
                Our coaches are carefully selected based on a rigorous set of criteria to ensure high-quality coaching
                and matched to a User through our proprietary Wand Coach Technology. Our coaches’ commitment and
                authenticity are key to establishing rapport and trust, and to having Users take responsible actions to
                improve themselves through their pre-determined goals. Our coaches will use a variety of questioning and
                self-discovery processes to probe Users to be aware of their interferences and ways to overcome these
                interferences, while co-creating options and action plans with Users. The role of our coaches is not to
                give advice or resolve problems for a User, but to facilitate the User’s own process of discovery,
                growth and achievement.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>7.3</p>
            </div>
            <div className="content-right">
              <p>
                Our coaches work within the ethical guidelines set by professional coaching bodies and Wand Coach’s own
                standards, maintaining strict confidentiality of the content(s) of each Coaching session, unless harm
                (whether to self, others or to property) has been indicated, in which case WIN will bring such matters
                of concern to the User’s employer and/or the relevant authorities for further attention and action.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>8.</b>
            </div>
            <div className="content-right">
              <b>Obligations and Restrictions</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>8.1</p>
            </div>
            <div className="content-right">
              <p>
                Users may only use the Platform and/or Services in compliance with applicable laws and for legitimate
                purposes. Users agree to comply with this Terms, all local rules and laws regarding the use of the
                Platform and/or Services, including Users’ online conduct, that are applicable in their jurisdictions.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>8.2</p>
            </div>
            <div className="content-right">
              <p>In using the Platform, Services and/or Content, Users must not:</p>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(a)</p>
                </div>
                <div className="content-right">
                  <p>
                    use it for any purpose that is improper or unlawful in violation of any applicable local, state,
                    national or international law, or any regulations having the force of law, or this Terms;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(b)</p>
                </div>
                <div className="content-right">
                  <p>
                    use it in any manner that could damage, disable or impair WIN’s server, or interfere with other
                    Users’ access or use of the Platform and security related feature of the Platform;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(c)</p>
                </div>
                <div className="content-right">
                  <p>
                    disassemble, reverse engineer, decompile, modify or otherwise creating derivative works based on the
                    Wand Coach Technology, any software, applications, updates or hardware contained in or available via
                    the Platform;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(d)</p>
                </div>
                <div className="content-right">
                  <p>
                    do anything or cause an action to be done resulting in the infringement or violation of WIN’s
                    Intellectual Property Rights;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(e)</p>
                </div>
                <div className="content-right">
                  <p>
                    attempt to gain unauthorized access to the Platform by using other Users’ Account through means of
                    hacking, mining or other means of interference;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(f)</p>
                </div>
                <div className="content-right">
                  <p>solicit personal information from anyone under the age of eighteen (18);</p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(g)</p>
                </div>
                <div className="content-right">
                  <p>
                    harvest or collect e-mail addresses or other contact information of other Users by electronic or
                    other means for the purposes of sending unsolicited e-mails or other unsolicited communications;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(h)</p>
                </div>
                <div className="content-right">
                  <p>
                    interfere with or disrupt the Platform, or servers or networks connected to the Platform, or disobey
                    any requirements, procedures, policies or regulations of networks connected to the Platform, or
                    circumvent any technology used to protect the Platform;
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(i)</p>
                </div>
                <div className="content-right">
                  <p>
                    obtain or attempt to access or otherwise obtain any materials, Content or information through any
                    means not intentionally made available or provided for through the Platform and/or Services; and/or
                  </p>
                </div>
              </div>
              <div className="page-content sub-content">
                <div className="content-left">
                  <p>(j)</p>
                </div>
                <div className="content-right">
                  <p>
                    remove, alter or replace any notices of authorship, trademarks, business names, logos or other
                    designations of origin on the Platform or pass off or attempt to pass off the Platform, Content
                    and/or Services as the product of anyone other than WIN.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>8.3</p>
            </div>
            <div className="content-right">
              <p>
                WIN reserves the right to investigate and take appropriate legal action against anyone who, in its
                opinion, violates the above provisions, including without limitation, suspending or terminating the
                Account of such violators and reporting them to the law enforcement authorities.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>8.4</p>
            </div>
            <div className="content-right">
              <p>
                Users are solely responsible for their own hardware, internet connection or telecommunication charges
                incurred for accessing, connecting to or using the Platform and/or Services.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>9.</b>
            </div>
            <div className="content-right">
              <b>Updates, Modifications and Availability</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>9.1</p>
            </div>
            <div className="content-right">
              <p>
                WIN may modify or upgrade the features and functionality of the Platform and/or Services from time to
                time, and reserve the right to make upgrades, updates, modifications and changes as it deems fit. Users
                understand that such upgrades or changes may result in interruption, modification, failure, delay or
                discontinuation of the Platform, Services or any function or feature thereof. In this regard, Users
                acknowledge and agree that WIN assumes no liability, responsibility or obligation for any such
                interruption, modification, failure, delay or discontinuation associated with the Platform and/or
                Services.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>9.2</p>
            </div>
            <div className="content-right">
              <p>
                Notwithstanding anything contained in this Terms, WIN reserves the right to modify or discontinue,
                temporarily or permanently, the Platform and/or Services (or any part thereof). Users agree that WIN
                shall not be liable to Users or to any third party for any modification, suspension or discontinuance of
                the Platform and/or Services.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>10.</b>
            </div>
            <div className="content-right">
              <b>Intellectual Property</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>10.1</p>
            </div>
            <div className="content-right">
              <p>
                The Platform, Services, Content and Wand Coach Technology underlying the Platform are the property of
                WIN (i.e. Wand Inspiration Network Pte Ltd) and/or its licensors, and are protected by copyright and/or
                other intellectual property rights. Except as expressly authorised by WIN, Users agree to not modify,
                copy, frame, scrape, rent, lease, loan, sell, distribute or create derivative works based on the
                Platform, Services and/or Content, in whole or in part. The words “<b>Wand”</b>, “
                <b>Wand Inspiration</b>” and “<b>Wand Coach</b>” and associated logos are the trademarks, trade names
                and/or service marks of WIN, and Users agree not to display or use in any manner such names and/or marks
                without WIN’s prior written authorisation. In using the Platform and/or Services, Users shall not engage
                in or use any data mining, robots, scraping or similar data gathering or extraction methods. Users
                further acknowledge that they have no right to have access to any aspect of the Platform in source-code
                form, and agree not to copy, modify, create a derivative work of, reverse engineer, reverse assemble or
                otherwise attempt to discover any source code, sell, assign, sublicense, or otherwise transfer any right
                in Wand Coach Technology. Any uses of the Platform, Services and/or Content not expressly permitted
                herein are prohibited, and any rights thereof not expressly granted herein are reserved by WIN.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>10.2</p>
            </div>
            <div className="content-right">
              <p>
                By using the Platform and/or Services, Users will not acquire any right, title or interest in or to the
                Intellectual Property Rights except for the limited license to use the Platform and/or Services granted
                to Users pursuant to this Terms. Nothing in this Terms or the Services should be construed as granting,
                by implication, estoppel, or otherwise, any license or right to use any of WIN’s trade or service marks
                displayed on the Platform without WIN’s prior written permission in each instance. All goodwill
                generated from the use of the Platform, Services and/or Content will inure to WIN’s exclusive benefit.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>10.3</p>
            </div>
            <div className="content-right">
              <p>
                To the extent where Users provide WIN with any suggestions, comments, improvements, ideas or other
                feedback (“<b>Feedback</b>”), Users hereby assign ownership of all intellectual property rights
                subsisting in that Feedback to WIN and acknowledge that WIN may use and share such Feedback for any
                purpose in its sole discretion.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>10.4</p>
            </div>
            <div className="content-right">
              <p>
                Other company, product, and service names and logos used and displayed via the Platform may be
                trademarks or service marks of their respective owners who may or may not be endorsed by, or affiliated
                with, or connected to WIN.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>11.</b>
            </div>
            <div className="content-right">
              <b>Third Party Resources</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>11.1</p>
            </div>
            <div className="content-right">
              <p>
                The Platform may contain links to third party’s websites, products, services, information, advertisement
                or other materials (“Third Party Resources”) that are not owned or controlled by WIN, or the Services
                may be accessible through Third Party Resources. Links to Third Party Resources do not constitute an
                endorsement or recommendation by WIN of such Third Party Resources.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>11.2</p>
            </div>
            <div className="content-right">
              <p>
                When Users access Third Party Resources, Users do so at their own risk. Users hereby represent and
                warrant that they have read and agree to be bound by all applicable policies of any Third Party
                Resources relating to the use of their services and act in accordance with those policies, in addition
                to Users’ obligations under this Terms.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>11.3</p>
            </div>
            <div className="content-right">
              <p>
                WIN has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or
                practices of or opinions expressed in any Third Party Resources. In addition, WIN shall not and cannot
                monitor, verify, censor or edit the content of any Third Party Resources. Users expressly relieve and
                hold WIN harmless from any and all liability arising from the use of any Third Party Resources.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>12.</b>
            </div>
            <div className="content-right">
              <b>Disclaimers</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>12.1</p>
            </div>
            <div className="content-right">
              <p>
                This Terms set out the full extent of our obligations and liabilities in respect of the Platform and
                Services.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>12.2</p>
            </div>
            <div className="content-right">
              <p>
                WIN PROVIDES THE PLATFORM AND SERVICES ON AN "AS IS" AND "AS AVAILABLE" BASIS. USERS AGREE THAT USE OF
                THE PLATFORM AND/OR SERVICES IS AT THEIR SOLE RISK. WIN WILL NOT BE LIABLE FOR DELAYS, INTERRUPTIONS,
                SERVICE FAILURES OR OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS OR
                OTHER SYSTEMS OUTSIDE THE REASONABLE CONTROL OF WIN.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>12.3</p>
            </div>
            <div className="content-right">
              <b>
                WE ARE NOT A HEALTH CARE OR MEDICAL ADVICE PROVIDER, NOR SHOULD THE SERVICES AND/OR CONTENT OF OUR
                PLATFORM BE CONSIDERED MEDICAL ADVICE OR THERAPY SERVICES. ONLY YOUR PHYSICIAN OR OTHER HEALTH CARE
                PROVIDER CAN OFFER MEDICAL ADVICE AND SERVICES TO YOU. IF YOU ARE THINKING ABOUT SUICIDE OR IF YOU ARE
                CONSIDERING HARMING YOURSELF OR OTHERS OR IF YOU FEEL THAT ANY OTHER PERSON MAY BE IN ANY DANGER OR IF
                YOU HAVE ANY MEDICAL EMERGENCY, YOU MUST IMMEDIATELY SEEK MEDICAL HELP AND NOTIFY THE RELEVANT
                AUTHORITIES.
              </b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>12.4</p>
            </div>
            <div className="content-right">
              <p>
                EXCEPT AS SET FORTH IN THIS SECTION, WIN MAKES NO WARRANTIES, EXPRESS OR IMPLIED, STATUTORY OR
                OTHERWISE, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR
                PURPOSE, OR NON-INFRINGEMENT.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>12.5</p>
            </div>
            <div className="content-right">
              <b>COACHING DOES NOT CONSTITUTE MEDICAL ADVICE OR THERAPY.</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>12.6</p>
            </div>
            <div className="content-right">
              <p>
                In order to enhance the coaching relationship, Users agree to communicate honestly, be open to feedback
                and assistance and to create the time and energy to participate fully during their Coaching sessions.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>13.</b>
            </div>
            <div className="content-right">
              <b>Exclusion and Limitation of Liability</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>13.1</p>
            </div>
            <div className="content-right">
              <p>
                TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL WIN BE LIABLE FOR ANY DIRECT, INDIRECT,
                INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES OR LOSSES OF ANY KIND
                IN ANY MANNER IN CONNECTION WITH OR ARISING OUT OF THIS TERMS, THE PLATFORM, SERVICES OR CONTENT,
                REGARDLESS OF THE FORM OF ACTION OR THE BASIS OF THE CLAIM OR WHETHER OR NOT WE HAVE BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS OR OPPORTUNITY,
                BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL OR PERSONAL DAMAGES OR LOSSES. IN THE EVENT THAT
                APPLICABLE LAW DOES NOT ALLOW THE EXCLUSION OF WARRANTIES STATED HEREIN OR THE LIMITATION OF LIABILITY
                STATED HEREIN, THEN USERS EXPRESSLY AGREE THAT IN NO EVENT WILL WIN’S LIABILITY FOR ANY CLAIM OR DAMAGES
                HEREUNDER EXCEED THE AMOUNT PAID BY THE RELEVANT USER’S EMPLOYER TO WIN IN RESPECT OF ANY SERVICE BEING
                THE SUBJECT OF THAT USER’S CLAIM.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>14.</b>
            </div>
            <div className="content-right">
              <b>Indemnity and Release</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>14.1</p>
            </div>
            <div className="content-right">
              <p>
                Users hereby agree to indemnify WIN, including its directors, officers, employees, coaches or agents (“
                <b>Indemnified Parties</b>”) and keep the Indemnified Parties harmless from and against any claims,
                actions, suits, proceedings, damages and/or liabilities whatsoever made against the Indemnified Parties
                arising from the use of the Platform, Services, Content and/or performance of Users’ duties and
                obligations under this Terms save where such claims, actions, suits, proceedings, damages and/or
                liabilities arise from the gross negligence, wilful misconduct or breach of this Terms by the
                Indemnified Parties.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>15.</b>
            </div>
            <div className="content-right">
              <b>Term, Suspension and Termination</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>15.1</p>
            </div>
            <div className="content-right">
              <p>
                The agreement between WIN and each User under this Terms shall take effect upon the earlier of (a) the
                User’s commencement of the use of the Platform and/or Services, or (b) registration of an Account, or
                (c) execution of a written agreement between the User’s employer with WIN in respect of the use of the
                Wand Coach Services, and will remain in full force and effect for as long as the relevant User continue
                to use the Platform and/or Services and/or the written agreement between the User’s employer with WIN
                remains in force.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>15.2</p>
            </div>
            <div className="content-right">
              <p>
                WIN may in its absolute discretion immediately suspend, discontinue or terminate a User’s access to
                and/or use of the Platform, Services and/or its Account under this Terms in the event of (i) any actual
                or suspected breach of any provisions under this Terms, or (ii) any actual or suspected breach by a
                User’s employer of the written agreement entered into with WIN for the provision of the Services, with
                or without notice at any time without incurring any liability whatsoever to the User or any third party.
                In this instance, WIN reserves the right to remove and discard data in and content of the User’s
                Account. Any suspected fraudulent, abusive or illegal activity that may be grounds for such suspension,
                discontinuance or termination may be referred to the appropriate law enforcement authorities.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>15.3</p>
            </div>
            <div className="content-right">
              <p>
                Upon termination of the agreement with a User pursuant to this Terms and/or the termination of the
                written agreement entered into with the User’s employer for the provision of the Services, the User’s
                access rights to the Platform and/or Services and other rights hereunder shall terminate with immediate
                effect.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>15.4</p>
            </div>
            <div className="content-right">
              <p>
                All provisions of this Terms which, by their nature, should survive termination, shall survive
                termination, including but not limited to ownership provisions, warranty disclaimers, and limitations of
                liability.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>16.</b>
            </div>
            <div className="content-right">
              <b>General</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>16.1</p>
            </div>
            <div className="content-right">
              <p>
                No delay, neglect or forbearance on the part of a Party in enforcing against the other Party any term or
                condition of this Terms shall either be or deemed to be a waiver or in any way prejudice any right of
                that Party under this Terms.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>16.2</p>
            </div>
            <div className="content-right">
              <p>
                If any provision of this Terms is prohibited by law or judged by a court to be unlawful, void or
                unenforceable, the provision shall, to the extent required be severed from this Terms and rendered
                ineffective as far as possible without modifying the remaining provision of this Terms, and shall not in
                any way affect any other circumstances of or the validity or enforcement of this Terms.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>16.3</p>
            </div>
            <div className="content-right">
              <p>
                Save as otherwise specifically provided in this Terms, the Parties hereunder shall not be liable for
                failures or delays in performing their obligations hereunder arising from any Event of Force Majeure,
                and in the event of any such delay, the time for all Parties’ performance shall be extended for a period
                equal to the time lost by reason of the delay which shall be remedied with all due dispatch in the
                circumstances.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>16.4</p>
            </div>
            <div className="content-right">
              <p>
                This Terms and all rights and obligations thereof are not assignable, transferable or sub-licensable by
                Users without WIN’s prior written consent. WIN may transfer, assign or delegate this Terms and its
                rights and obligations thereof without prior notice to or consent by Users.
              </p>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <b>17.</b>
            </div>
            <div className="content-right">
              <b>Governing Law and Jurisdiction</b>
            </div>
          </div>
          <div className="page-content">
            <div className="content-left">
              <p>17.1</p>
            </div>
            <div className="content-right">
              <p>
                Irrespective of the country from which Users access or use the Platform and/or Services, to the extent
                permitted by law, this Terms and Users’ use of the Platform and/or Services shall be governed in
                accordance with the laws of Singapore. Users hereby agree to submit to the exclusive jurisdiction of the
                courts of Singapore to resolve any claims or disputes which may arise in connection with this Terms.
              </p>
            </div>
          </div>
          <div className="page-content">
            <i>Last Updated: {moment('2024-10-28', 'YYYY-MM-DD').format('DD MMM YYYY')}</i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
