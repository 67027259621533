import React, { useState } from 'react';
import { Modal, Button, Typography, Checkbox, Row, Col } from 'antd';

const CookiePopup = () => {
  const [isModalVisible, setIsModalVisible] = useState(!localStorage.getItem('wandCookieConsentData'));
  const [isManaged, setIsManaged] = useState(false);
  const [cookies, setCookies] = useState({
    necessary: true,
    functional: false,
    performance: false,
    targeting: false,
  });

  const handleAccept = () => {
    localStorage.setItem('wandCookieConsentData', 'accepted');
    setIsModalVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem('wandCookieConsentData', 'rejected');
    setIsModalVisible(false);
  };

  const handleCheckboxChange = (type) => {
    setCookies((prevCookies) => ({
      ...prevCookies,
      [type]: !prevCookies[type],
    }));
  };

  const saveConsent = () => {
    localStorage.setItem('wandCookieConsentData', JSON.stringify(cookies));
    setIsModalVisible(false);
  };

  const handleManage = () => {
    setIsManaged(true);
  };

  const validShowContent = ![
    'cookie-policy',
    'privacy-policy',
    'terms-of-service',
    'pp-europe-and-the-uk',
    'pp-us',
  ].some((path) => window.location.pathname.includes(path));

  return (
    <Modal
      title={<Typography.Title level={4}>We value your privacy</Typography.Title>}
      open={isModalVisible && validShowContent}
      footer={null}
      closable={false}
      centered
    >
      {!isManaged && (
        <>
          {' '}
          <Typography.Paragraph>
            At Wand Coach, we use cookies and similar technologies for a number of purposes, including to improve your
            experience on our Platform. Read our Cookie Policy{' '}
            <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </Typography.Paragraph>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Button onClick={handleManage}>Manage Cookies</Button>
            <Button onClick={handleReject}>Reject all cookies</Button>
            <Button type="primary" onClick={handleAccept}>
              Accept all cookies
            </Button>
          </div>
        </>
      )}

      {isManaged && (
        <>
          <p>Welcome to Wand Coach!</p>
          <p>
            The following types of cookies are used to improve your experience on our Platform and Services. Please
            provide your consent for these types of cookie usage.
          </p>
          <Row>
            <Col span={24}>
              <Checkbox checked={cookies.necessary} disabled>
                I accept the necessary cookies only
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox checked={cookies.functional} onChange={() => handleCheckboxChange('functional')}>
                I accept the functional cookies
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox checked={cookies.performance} onChange={() => handleCheckboxChange('performance')}>
                I accept the performance cookies
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox checked={cookies.targeting} onChange={() => handleCheckboxChange('targeting')}>
                I accept the targeting cookies
              </Checkbox>
            </Col>
          </Row>

          <Button type="primary" onClick={saveConsent} style={{ marginTop: '16px' }}>
            Save Selection
          </Button>
        </>
      )}
    </Modal>
  );
};

export default CookiePopup;
