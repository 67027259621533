/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import './Register.scss';
import { Button, Checkbox, Form, Input, Radio, Select, Space, Spin } from 'antd';
import { AuthPages } from '../Pages/Auth';
import { COACH_PROPERTY_TYPE } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { doSignUp } from '../modules/auth/stores/thunk';
import { setInitError } from '../modules/auth/stores/slice';
import { Link } from 'react-router-dom';

const RegisterPage = ({ currentPage, setCurrentPage }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.auth.isFetching);
  const isError = useSelector((state) => state.auth.isError);

  const [formData, setFormData] = useState({});
  const [checkedPolicy, setCheckedPolicy] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [title, setTitle] = useState('PERSONAL INFORMATION');
  const [description, setDescription] = useState('Provide detail information about you');

  useEffect(() => {
    if (currentPage === AuthPages.login) form.resetFields();
  }, [currentPage]);

  useEffect(() => {
    setCurrentStep(1);
    dispatch(setInitError());
  }, [isError]);

  const handleSubmit = () => {
    delete formData.confirmPassword;
    const params = {};
    const normalKeys = ['email', 'familyName', 'givenName', 'password', 'accessCode'];
    Object.keys(formData).forEach((key) => {
      if (normalKeys.includes(key)) {
        params[key] = formData[key];
      }
    });
    dispatch(doSignUp({ ...params }));
  };

  const clickContinue = async (e) => {
    e.preventDefault();
    form
      .validateFields()
      .then(() => setCurrentStep(currentStep + 1))
      .catch(() => console.log('false'));
  };

  const onValuesChange = (changedValues, allValues) => {
    setFormData({ ...formData, ...allValues });
  };

  useEffect(() => {
    if (currentStep === 2) {
      setTitle('SETUP PASSWORD');
      setDescription('Create a password to finish registration');
    }
  }, [currentStep]);

  const validatePassword = (_, value) => {
    if (!value || form.getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('The two passwords do not match!'));
  };

  return (
    <Spin spinning={isFetching}>
      <div className="RegisterPage">
        <div className="header" onClick={() => setCurrentPage && setCurrentPage(AuthPages.login)}>
          <ReactSVG className="icon" src="/icons/arrow_back.svg" />
          <p>Create an account</p>
        </div>
        <div className="body">
          <div className="content">
            <div className="steps">
              <div className={`step ${currentStep === 1 ? 'processing' : currentStep > 1 ? 'finished' : 'wait'}`}>
                <div className="step-content">1</div>
                <div className="line"></div>
              </div>
              <div className={`step ${currentStep === 2 ? 'processing' : currentStep > 2 ? 'finished' : 'wait'}`}>
                <div className="step-content">2</div>
              </div>
            </div>

            <div className="title">
              <h2>{title}</h2>
              <p>{description}</p>
            </div>

            <div className="FormContent">
              <Form
                form={form}
                scrollToFirstError
                layout="vertical"
                requiredMark={false}
                colon={false}
                onValuesChange={onValuesChange}
                onFinish={handleSubmit}
              >
                {currentStep === 1 && (
                  <>
                    <Form.Item label="Family Name" name="familyName" rules={[{ required: true, message: 'Required' }]}>
                      <Input size="large" placeholder="Family Name" />
                    </Form.Item>
                    <Form.Item label="Given Name" name="givenName" rules={[{ required: true, message: 'Required' }]}>
                      <Input size="large" placeholder="Given Name" />
                    </Form.Item>
                    <Form.Item
                      label="Email Address"
                      name="email"
                      rules={[
                        { type: 'email', message: 'Invalid Email' },
                        { required: true, message: 'Required' },
                      ]}
                    >
                      <Input size="large" placeholder="Email Address" />
                    </Form.Item>
                  </>
                )}
                {currentStep === 2 && (
                  <>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: currentStep === 3,
                          message: 'Please input your password!',
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password placeholder="Password" size="large" />
                    </Form.Item>

                    <Form.Item
                      name="confirmPassword"
                      label="Confirm Password"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: currentStep === 3,
                          message: 'Please confirm your password!',
                        },
                        {
                          validator: validatePassword,
                        },
                      ]}
                    >
                      <Input.Password size="large" placeholder="Confirm Password" />
                    </Form.Item>
                    <Form.Item
                      label="Access Code"
                      name="accessCode"
                      rules={[
                        { required: true, message: 'Required' },
                        { min: 4, message: 'Access Code must be minimum 4 characters.' },
                      ]}
                    >
                      <Input size="large" placeholder="Access Code" />
                    </Form.Item>
                  </>
                )}
              </Form>
            </div>
          </div>
        </div>
        <div className="footer">
          {currentStep < 2 ? (
            <Button type="primary" htmlType="submit" block size="large" onClick={clickContinue}>
              Continue
            </Button>
          ) : (
            <>
              <div className="policy">
                <Checkbox onChange={(e) => setCheckedPolicy(e.target.checked)} />
                <p>
                  By completing registration, I agree with Wand Inspiration’s
                  <a>
                    <Link to="/terms-of-service" target="_blank">
                      Terms of Service
                    </Link>{' '}
                    &{' '}
                    <Link to="/privacy-policy" target="_blank">
                      Privacy Policy
                    </Link>
                  </a>
                </p>
              </div>

              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                }}
                block
                disabled={!checkedPolicy}
                size="large"
              >
                Complete Registration
              </Button>
            </>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default RegisterPage;
